/* styles/Home.module.css */
.background {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background: #ffffff;
  }
  
  .particle {
    position: absolute;
    width: 30px;
    height: 30px;
    pointer-events: none;
    opacity: 0;
  }
  
  .text-base {
    font-size: 1rem;
  }
  
  .lg\\:text-xl {
    font-size: 1.25rem;
  }
  
  .text-secondary-foreground {
    color: #f60b0b; /* Adjust this color to your theme's secondary foreground color */
  }
  
  .text-black {
    color: #000000;
  }
  